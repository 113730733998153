import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useRef } from "react";
import { AddDuckRequest } from "./duckStore";

interface AddDuckDialogProps {
  onSubmit: (ducks: AddDuckRequest) => void;
  onClose: () => void;
  open: boolean;
}

export default function AddDuckDialog({
  open,
  onClose,
  onSubmit,
}: AddDuckDialogProps) {
  const fileInput = useRef<HTMLInputElement>(null);

  async function handleSubmit() {
    if (fileInput.current?.files) {
      const request: AddDuckRequest = { requests: [], images: [] };
      for (let i = 0; i < fileInput.current.files.length; i++) {
        const file = fileInput.current.files.item(i);
        if (!file) continue;

        request.requests.push({
          duck: {
            name: file.name.replace(/\.svg/g, ""),
          },
        });
        request.images.push(file);
      }

      onSubmit(request);
    }

    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Ducks</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginBottom: 2 }}>
          Please select the files of the ducks you want to add.
        </DialogContentText>
        <input type="file" accept=".svg" multiple={true} ref={fileInput} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Add</Button>
      </DialogActions>
    </Dialog>
  );
}
