import { AccessoryItem } from "../types";
import {
  Card,
  CardHeader,
  CardMedia,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/pro-regular-svg-icons";
import React, { useState } from "react";
import { accessoryApi } from "./accessoryStore";

interface AccessoryItemProps {
  item: AccessoryItem;
}

export default function AccessoryCard({ item }: AccessoryItemProps) {
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>();
  function openMenu(e: React.MouseEvent<HTMLElement>) {
    setMenuAnchor(e.currentTarget);
  }
  function closeMenu() {
    setMenuAnchor(null);
  }

  const [, setIsRenameDialogOpen] = useState(false);
  const [removeAccessoryItem] = accessoryApi.useRemoveItemMutation();

  function openRenameDialog() {
    closeMenu();
    setIsRenameDialogOpen(true);
  }

  function openDeleteDialog() {
    closeMenu();
    removeAccessoryItem(item.id);
  }

  return (
    <Card sx={{ width: 200, margin: 2 }}>
      <CardHeader
        action={
          <IconButton
            aria-label="settings"
            id="settings-button"
            aria-controls={menuAnchor ? "settings-menu" : undefined}
            aria-expanded={menuAnchor ? "true" : undefined}
            aria-haspopup="true"
            onClick={openMenu}
          >
            <FontAwesomeIcon icon={faEllipsisVertical} />
          </IconButton>
        }
        subheader={item.name}
      />
      <Menu
        id="settings-menu"
        MenuListProps={{
          "aria-labelledby": "settings-menu",
        }}
        anchorEl={menuAnchor}
        open={!!menuAnchor}
        onClose={closeMenu}
      >
        <MenuItem onClick={openRenameDialog}>Rename</MenuItem>
        <MenuItem onClick={openDeleteDialog}>Delete</MenuItem>
      </Menu>
      <CardMedia
        component="img"
        image={process.env.REACT_APP_BASE_URL + item.image}
        alt=""
        sx={{ padding: "40px" }}
      />
    </Card>
  );
}
