import { FetchArgs } from "@reduxjs/toolkit/query";

type Body<T extends string> = { [s in T]: object } & { image?: File };
export default function withFormDataBody<K extends string, T extends Body<K>>(
  key: K,
  args: FetchArgs
): (body: T) => FetchArgs {
  return (body: T) => {
    const formData = new FormData();
    if (body.image) formData.append("image", body.image);
    formData.append(key, JSON.stringify(body[key]));

    return { ...args, body: formData };
  };
}

type ArrayBody<T extends string> = { [s in T]: object } & { images?: File[] };
export function withFormDataArrayBody<K extends string, T extends ArrayBody<K>>(
  key: K,
  args: FetchArgs
): (body: T) => FetchArgs {
  return (body: T) => {
    const formData = new FormData();
    for (let image of body?.images ?? []) formData.append("images", image);
    formData.append(key, JSON.stringify(body[key]));

    return { ...args, body: formData };
  };
}
