import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useRef } from "react";
import { AddAccessoriesRequest } from "./accessoryStore";

interface AddAccessoryDialogProps {
  onSubmit: (ducks: AddAccessoriesRequest) => void;
  onClose: () => void;
  open: boolean;
  categoryId: string;
}

const AddAccessoryDialog = ({
  onSubmit,
  onClose,
  open,
  categoryId,
}: AddAccessoryDialogProps) => {
  const fileInput = useRef<HTMLInputElement>(null);

  async function handleSubmit() {
    if (fileInput.current?.files) {
      const accessories: AddAccessoriesRequest = { images: [], requests: [] };
      for (let i = 0; i < fileInput.current.files.length; i++) {
        const file = fileInput.current?.files?.[i] as File;
        accessories.requests.push({
          name: file.name.replace(/\.svg/g, ""),
          categoryId,
        });
        accessories.images.push(file);
      }
      onSubmit(accessories);
    }

    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>New Accessories</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginBottom: 2 }}>
          Please select the files of the ducks you want to add.
        </DialogContentText>
        <input type="file" accept=".svg" multiple={true} ref={fileInput} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Add</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddAccessoryDialog;
