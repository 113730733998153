import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useRef, useState } from "react";
import { fromAddressText } from "mf-google-geocoder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-regular-svg-icons/faSearch";
import * as yup from "yup";
import { AddEventRequest } from "./eventsStore";

interface AddEventDialogProps {
  onSubmit: (event: AddEventRequest) => void;
  onClose: () => void;
  open: boolean;
}

const validationSchema = yup.object({
  _event: yup.object({
    name: yup.string().required("Please give the event a name"),
    date: yup.mixed<Dayjs>().required("Please give the event a date"),
    location: yup
      .object({
        type: yup.string().matches(/Point/),
        coordinates: yup.array().of(yup.number()).length(2),
      })
      .required(
        "Please give the event a location. If you typed one in, don't forget to click the search icon."
      ),
  }),
  image: yup.mixed().required("Please give the event an image advertisement"),
});

export default function Add_eventDialog({
  open,
  onClose,
  onSubmit,
}: AddEventDialogProps) {
  const [isMapLoading, setIsMapLoading] = useState(false);
  const [locationText, setLocationText] = useState("");
  const [adImage, setAdImage] = useState("");
  const fileInput = useRef<HTMLInputElement>(null);
  async function onAdChange() {
    const file = fileInput.current?.files?.[0];
    if (!file) return;

    await form.setFieldValue("image", file);
    await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = () => {
        setAdImage(reader.result?.toString() ?? "");
        resolve(null);
      };

      reader.readAsDataURL(file);
    });
  }

  function handleSubmit(values: AddEventRequest) {
    onSubmit(values);
    onClose();
  }

  const form = useFormik<AddEventRequest>({
    initialValues: {
      _event: {
        name: "",
        date: dayjs(),
        location: {
          type: "Point",
          coordinates: [0, 0],
        },
        city: "",
        state: "",
      },
      image: new File([], ""),
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  async function doGeocode() {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const mapEl = document.getElementById("map");
    if (!mapEl || !apiKey) return;

    setIsMapLoading(true);
    try {
      const { location, city, state } = await fromAddressText(locationText, {
        apiKey,
      });
      const map = new google.maps.Map(mapEl, {
        center: location,
        zoom: 15,
      });
      new google.maps.Marker({
        position: location,
        map,
      });
      await form.setFieldValue("_event.location", {
        type: "Point",
        coordinates: [location.lat, location.lng],
      });
      await form.setFieldValue("_event.city", city ?? "");
      await form.setFieldValue("_event.state", state ?? "");
    } finally {
      setIsMapLoading(false);
    }
  }
  const hasLocation =
    form.values._event.location.coordinates[0] !== 0 ||
    form.values._event.location.coordinates[1] !== 0;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>New Event</DialogTitle>
      <DialogContent>
        <Stack direction="column" py={2} gap={2}>
          <TextField
            name="_event.name"
            id="name"
            label="Name"
            onChange={form.handleChange}
            value={form.values._event.name}
            error={!!(form.touched._event?.name && form.errors._event?.name)}
            helperText={form.errors._event?.name}
          />
          <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            label="Date"
            onChange={(newValue) => form.setFieldValue("_event.date", newValue)}
            value={form.values._event.date}
          />
          {form.touched._event?.name && form.errors._event?.name && (
            <em>{form.errors._event?.name}</em>
          )}
          <label>
            Ad:
            <input
              type="file"
              accept="image"
              multiple={true}
              ref={fileInput}
              onChange={onAdChange}
            />
          </label>
          {form.values.image.size > 0 && (
            <img src={adImage} alt="_event flyer" />
          )}
          <TextField
            name="_event.location"
            id="location"
            label="Location"
            onChange={(e) => setLocationText(e.target.value)}
            value={locationText}
            disabled={isMapLoading}
            helperText="Press the search icon when you're done!"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={doGeocode} disabled={isMapLoading}>
                    <FontAwesomeIcon icon={faSearch} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box height={hasLocation ? 300 : 0} id="map" />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={form.handleSubmit as () => any}
          disabled={!form.isValid || !hasLocation}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
