import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AccessoryItem, AccessoryCategory, NewOf, PagedQuery } from "../types";
import withFormDataBody, {
  withFormDataArrayBody,
} from "../utils/withFormDataBody";

interface CategoryQuery extends PagedQuery {
  categoryId: string;
}

interface AccessoryResponse {
  accessoryItems: AccessoryItem[];
  accessoryCategories: AccessoryCategory[];
  accessoryItemCount: number;
  accessoryCategoryCount: number;
}

export interface AddAccessoriesRequest {
  requests: NewOf<AccessoryItem>[];
  images: File[];
}

export interface AddCategoryRequest {
  request: NewOf<AccessoryCategory>;
  image?: File;
}

const ACCESSORY_TAG = "Accessory";
const CATEGORY_TAG = "Category";

export const accessoryApi = createApi({
  reducerPath: "accessoryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/accessory`,
    credentials: "include",
    prepareHeaders: (h) => {
      h.set("x-requested-with", "XMLHttpRequest");
      return h;
    },
  }),
  tagTypes: [ACCESSORY_TAG, CATEGORY_TAG],
  endpoints: (build) => ({
    // Accessory API
    all: build.query<AccessoryResponse, PagedQuery>({
      query: (request) => ({
        url: `/accessories?resultsPerPage=${request.resultsPerPage}&pageNumber=${request.pageNumber}`,
        method: "GET",
      }),
      providesTags: [ACCESSORY_TAG],
    }),
    accessories: build.query<AccessoryResponse, CategoryQuery>({
      query: (request) => ({
        url: `/accessories/${request.categoryId}?resultsPerPage=${request.resultsPerPage}&pageNumber=${request.pageNumber}`,
        method: "GET",
      }),
      providesTags: [ACCESSORY_TAG],
    }),
    addItems: build.mutation<void, AddAccessoriesRequest>({
      query: withFormDataArrayBody("requests", {
        url: "/additems",
        method: "POST",
      }),
      invalidatesTags: [ACCESSORY_TAG],
    }),
    removeItem: build.mutation<{ success: boolean; id: number }, string>({
      query: (id) => ({
        url: `/remove/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [ACCESSORY_TAG],
    }),
    // ////////////////////
    // Category API
    // ////////////////////
    categories: build.query<AccessoryResponse, void>({
      query: () => ({
        url: `/categories`,
        method: "GET",
      }),
      providesTags: [CATEGORY_TAG],
    }),
    addCategory: build.mutation<void, AddCategoryRequest>({
      query: withFormDataBody("request", {
        url: "/category-add",
        method: "POST",
      }),
      invalidatesTags: [CATEGORY_TAG],
    }),
    updateCategory: build.mutation<void, AddCategoryRequest>({
      query: withFormDataBody("request", {
        url: "/category-update",
        method: "POST",
      }),
      invalidatesTags: [CATEGORY_TAG],
    }),
    deleteCategory: build.mutation<{ res: boolean }, string>({
      query: (id) => ({
        url: `/category-remove/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [CATEGORY_TAG],
    }),
  }),
});
