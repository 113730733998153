import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { useRef, useState } from "react";
import { AddCategoryRequest } from "./accessoryStore";

interface AddAccessoryCategoryDialogProps {
  onSubmit: (category: AddCategoryRequest) => void;
  onClose: () => void;
  open: boolean;
}

const AddAccessoryCategoryDialog = ({
  onSubmit,
  onClose,
  open,
}: AddAccessoryCategoryDialogProps) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const [fileContent, setFileContent] = useState("");
  const [imgSize, setImgSize] = useState({ w: 0, h: 0 });

  const [categoryName, setCategoryName] = useState("");
  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);
  const [zIndex, SetZIndex] = useState(1);

  async function handleSubmit() {
    if (fileInput.current?.files?.[0]) {
      onSubmit({
        request: {
          name: categoryName,
          left: Math.floor(left * 2),
          top: Math.floor(top * 2),
          zindex: zIndex,
        },
        image: fileInput.current?.files?.[0] as File,
      });
    }

    onClose();
  }

  const onSelectFile = async (e: any) => {
    setCategoryName(e.target.files[0].name.replace(/\.svg/g, ""));

    // get img natural size
    const url = URL.createObjectURL(e.target.files[0]);
    const img = new Image();
    img.onload = () => {
      setImgSize({ ...imgSize, w: img.naturalWidth, h: img.naturalHeight });
      setLeft(180 - img.naturalWidth / 4);
      if (top === 0) setTop(180 - img.naturalHeight / 4);
    };
    img.src = url;

    // set img url
    setFileContent(url);
  };

  const DuckModel = () => {
    return (
      <div
        style={{
          width: "360px",
          height: "360px",
          position: "relative",
          borderColor: "gray",
          borderWidth: "1px",
          borderStyle: "dashed",
          zIndex: "0",
        }}
      >
        <img
          style={{
            width: "360px",
            height: "360px",
            position: "absolute",
            transform: "translate(-50%, -50%)",
            left: "50%",
            top: "50%",
            zIndex: 0,
          }}
          src="/Naked Duck 720x720.svg"
          alt=""
        />
        <img
          style={{
            width: `${imgSize.w / 2}px`,
            height: `${imgSize.h / 2}px`,
            position: "absolute",
            left: `${left}px`,
            top: `${top}px`,
            zIndex: `${zIndex}`,
          }}
          src={fileContent}
          alt=""
        />
      </div>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"lg"}>
      <DialogTitle>New Category</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginBottom: 2 }}>
          Please select the file of the category you want to add.
        </DialogContentText>
        <Stack direction={"row"} spacing={5}>
          <DuckModel></DuckModel>
          <Stack direction="column" alignItems="center" marginBottom={4}>
            <input
              style={{ marginTop: 10 }}
              type="file"
              accept=".svg"
              multiple={false}
              ref={fileInput}
              onChange={onSelectFile}
            />
            <TextField
              style={{ marginTop: 10 }}
              id="standard-basic"
              label="Name"
              variant="standard"
              value={categoryName}
              onChange={(e) => {
                setCategoryName(e.target?.value);
              }}
            />
            <TextField
              style={{ marginTop: 10 }}
              id="standard-basic"
              type={"number"}
              label="Left"
              variant="standard"
              value={left}
              onChange={(e) => {
                setLeft(parseInt(e.target?.value));
              }}
            />
            <TextField
              style={{ marginTop: 10 }}
              id="standard-basic"
              type={"number"}
              label="Top"
              variant="standard"
              value={top}
              onChange={(e) => {
                setTop(parseInt(e.target?.value));
              }}
            />
            <TextField
              style={{ marginTop: 10 }}
              id="standard-basic"
              type={"number"}
              label="zIndex"
              variant="standard"
              value={zIndex}
              onChange={(e) => {
                SetZIndex(parseInt(e.target?.value));
              }}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Add</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddAccessoryCategoryDialog;
