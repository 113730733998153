import {
  Box,
  Container,
  Chip,
  CircularProgress,
  TextField,
  MenuItem,
  Pagination,
  Stack,
  Toolbar,
  Typography,
  FormControl,
  InputLabel,
  Button,
  Grid,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React, { useState } from "react";
import { accessoryApi } from "./accessoryStore";
import AddAccessoryDialog from "./AddAccessoryDialog";
import AccessoryCard from "./AccessoryCard";
import { AccessoryCategory, AccessoryItem } from "../types";
import AddAccessoryCategoryDialog from "./AddAccessoryCategoryDialog";

const Accessory = () => {
  // page number, results
  const [pageNumber, setPageNumber] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(20);
  // category
  const [currentCategoryId, setCurrentCategoryId] = useState("");
  const [currentCategory, setCurrentCategory] = useState<AccessoryCategory>();

  const onCategoryChangeHandler = (e: SelectChangeEvent) => {
    setCurrentCategoryId(e.target.value);
    categories?.accessoryCategories.forEach((item: AccessoryCategory) => {
      if (e.target.value === item.id) {
        setCurrentCategory({ ...currentCategory, ...item });
      }
    });
  };

  // redux api
  const {
    data: accessories,
    isSuccess,
    isLoading,
  } = accessoryApi.useAccessoriesQuery({
    categoryId: currentCategoryId,
    pageNumber,
    resultsPerPage,
  });
  const { data: categories } = accessoryApi.useCategoriesQuery();
  const [addAccessoryItems] = accessoryApi.useAddItemsMutation();
  const [addAccessoryCategory] = accessoryApi.useAddCategoryMutation();
  const [updateCategory] = accessoryApi.useUpdateCategoryMutation();
  const [deleteCategory] = accessoryApi.useDeleteCategoryMutation();

  // Add Category Dialog, Add Accessory Dialog
  const [addAccessoryDialogVisible, setAddAccessoryDialogVisible] =
    useState(false);
  const [
    addAccessoryCategoryDialogVisible,
    setAddAccessoryCategoryDialogVisible,
  ] = useState(false);

  function closeAddAccessoryDialog() {
    setAddAccessoryDialogVisible(false);
  }

  // Delete, Update Category API, Handler
  const onUpdateCategoryHandler = () => {
    currentCategory && updateCategory({ request: currentCategory });
  };

  const onDeleteCategoryHandler = () => {
    deleteCategory(currentCategoryId).then((res: any) => {
      if (res?.data === true) setCurrentCategoryId("");
    });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Accessories
        </Typography>
        <Stack direction="row" spacing={2}>
          <Chip
            label="Add Category"
            variant="outlined"
            onClick={() => {
              setAddAccessoryCategoryDialogVisible(true);
            }}
          />
          <Chip
            label="Add Accessory"
            variant="outlined"
            onClick={() => {
              setAddAccessoryDialogVisible(true);
            }}
          />
        </Stack>
      </Toolbar>
      <AddAccessoryDialog
        onSubmit={addAccessoryItems}
        onClose={closeAddAccessoryDialog}
        open={addAccessoryDialogVisible}
        categoryId={currentCategoryId}
      />
      <AddAccessoryCategoryDialog
        onSubmit={addAccessoryCategory}
        onClose={() => {
          setAddAccessoryCategoryDialogVisible(false);
        }}
        open={addAccessoryCategoryDialogVisible}
      />
      {isLoading && (
        <Stack justifyContent="center" alignItems="center">
          <CircularProgress />
        </Stack>
      )}

      {!isLoading && isSuccess && (
        <Stack direction="column" alignItems="center" gap={4} marginTop={4}>
          <Grid container spacing={4}>
            <Grid xs={3}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">
                  Categories
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={currentCategoryId}
                  label="Category"
                  onChange={onCategoryChangeHandler}
                >
                  <MenuItem value={""} key={""}>
                    All
                  </MenuItem>
                  {categories?.accessoryCategories?.map(
                    (category: AccessoryCategory) => {
                      return (
                        <MenuItem value={category.id} key={category.id}>
                          {category.name}
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
                {currentCategoryId !== "" && (
                  <Box>
                    <Stack
                      direction="column"
                      alignItems="center"
                      marginBottom={4}
                      spacing={3}
                      paddingTop={5}
                      padding={1}
                    >
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Name"
                        variant="standard"
                        value={currentCategory?.name}
                        onChange={(e) => {
                          currentCategory &&
                            setCurrentCategory({
                              ...currentCategory,
                              name: e.target.value,
                            });
                        }}
                      />
                      <TextField
                        fullWidth
                        id="standard-basic"
                        type={"number"}
                        label="Left"
                        variant="standard"
                        value={currentCategory?.left}
                        onChange={(e) => {
                          currentCategory &&
                            setCurrentCategory({
                              ...currentCategory,
                              left: parseInt(e.target.value),
                            });
                        }}
                      />
                      <TextField
                        fullWidth
                        id="standard-basic"
                        type={"number"}
                        label="Top"
                        variant="standard"
                        value={currentCategory?.top}
                        onChange={(e) => {
                          currentCategory &&
                            setCurrentCategory({
                              ...currentCategory,
                              top: parseInt(e.target.value),
                            });
                        }}
                      />
                      <TextField
                        fullWidth
                        id="standard-basic"
                        type={"number"}
                        label="zIndex"
                        variant="standard"
                        value={currentCategory?.zindex}
                        onChange={(e) => {
                          currentCategory &&
                            setCurrentCategory({
                              ...currentCategory,
                              zindex: parseInt(e.target.value),
                            });
                        }}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      spacing={2}
                    >
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={onDeleteCategoryHandler}
                      >
                        Delete
                      </Button>
                      <Button
                        variant="contained"
                        onClick={onUpdateCategoryHandler}
                      >
                        Save
                      </Button>
                    </Stack>
                  </Box>
                )}
              </FormControl>
            </Grid>
            <Grid xs={9}>
              <Container>
                {!isLoading &&
                  isSuccess &&
                  accessories.accessoryItems.length === 0 && (
                    <Stack justifyContent="center" alignItems="center">
                      There are no Accessories. Add some by pressing the + icon.
                    </Stack>
                  )}
                {!!accessories && accessories.accessoryItems.length > 0 && (
                  <Stack
                    direction="row"
                    flexWrap="wrap"
                    justifyContent="center"
                  >
                    {accessories.accessoryItems.map((item: AccessoryItem) => {
                      return <AccessoryCard item={item} key={item.id} />;
                    })}
                  </Stack>
                )}
                <Pagination
                  page={pageNumber}
                  onChange={(_, newPage) => setPageNumber(newPage)}
                  count={Math.ceil(
                    accessories.accessoryItemCount / resultsPerPage
                  )}
                  color="primary"
                  size="large"
                />
                <Stack direction="row" alignItems="center" marginBottom={4}>
                  <Typography marginRight={4}>Results Per Page:</Typography>
                  <Select
                    value={resultsPerPage}
                    label="Results Per Page"
                    onChange={(e) =>
                      setResultsPerPage(parseInt(e.target.value as string))
                    }
                  >
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="50">50</MenuItem>
                    <MenuItem value="100">100</MenuItem>
                  </Select>
                </Stack>
              </Container>
            </Grid>
          </Grid>
        </Stack>
      )}
    </Box>
  );
};

export default Accessory;
